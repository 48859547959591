import React from "react";
import STAR from "@assets/icons/Lims/star.svg";
import { StaticImage } from "gatsby-plugin-image";
import STAR_GRAY_1 from "@assets/icons/Lims/star-gray-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./LiveEditing.module.scss";
import { WhyLimsProps } from "@components/LimsSoftware/SectionWhyLims/data";
import { SectionWhyLIMS } from "@components/LimsSoftware/SectionWhyLims";

export const LiveEditing = () => {
	const props: WhyLimsProps = {
		title: "Live-editing and version control",
		starOnTitle: <SVG src={STAR_GRAY_1} className={styles.starGray1} />,
		description:
			"An Electronic lab notebook (ELN) provides live-editing and seamless version control, fostering team collaboration and keeping the entire team aligned.",
		list: [
			{
				text: "Live-editing and monitoring in experiments",
				icon: STAR,
				id: 1,
			},
			{
				text: "Seamless protocol version control",
				icon: STAR,
				id: 2,
			},

			{
				text: "Activity logs on experiments",
				icon: STAR,
				id: 3,
			},
		],
		bodyTextColor:'text-helper',
		link:"INSIGHTS",
		image: (
			<div className={styles.imageWrapper}>
				<StaticImage
					loading="eager"
					src={"../../../assets/images/eln/cloud-eln.png"}
					alt="Cloud ELN"
				/>
			</div>
		),
		gap: "gapLg",
		sectionWrapperOverwrite:styles.sectionWrapperOverwrite
	};
	return <SectionWhyLIMS {...props} />;
};
