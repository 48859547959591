import React, { ReactNode } from "react";
import * as styles from "./LearnMore.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { Accordion } from "@components/Accordion";
export const SectionLearnMore = () => {
	const getBodyElement = (content: string | ReactNode) => {
		return (
			<TypographyV2
				variant="BODY_TEXT_EXTRA_LARGE"
				mobileVariant="BODY_TEXT_EXTRA_LARGE"
				weight="REGULAR"
				color="text-helper"
			>
				{content}
			</TypographyV2>
		);
	};
	const QnAs = [
		{
			title: "What is ELN?",
			id: "1",
			defaultOpen: true,
			children: [
				`An Electronic Lab Notebook (ELN) is a software solution designed to replace a paper lab notebook or basic spreadsheet. They are used by scientists in many fields, particularly in the life sciences. ELNs offer numerous advantages, and the benefits will be countless if it is used alongside with Laboratory Information Management Systems (LIMS).`,
			],
		},
		{
			title: "Why labs should use an Electronic Lab Notebook (ELN)",
			id: "2",
			children: [
				`Transitioning to a fully digitized electronic lab notebook (ELN) offers numerous benefits, including streamlined data organization and accessibility, enhanced collaboration and sharing capabilities, improved data security and reliable backups, accurate version control with a clear audit trail, and seamless integration with various laboratory instruments and software.`
			],
		},
		{
			title: "Benefits of ELN",
			id: "3",
			children: [
				`Implementing an Electronic Lab Notebook (ELN) can revolutionize your laboratory into a cutting-edge hub of excellence. Primary advantages include increased efficiency through centralized data organization and quick access to information, greater accuracy by automating data entry and validation processes, and boosted productivity through enhanced collaboration, real-time data sharing, and smooth integration with various laboratory instruments and software.`
			],
		},
	];
	
	return (
		<div className={styles.container}>
			<TypographyV2
				variant="HEADING_2"
				weight="MEDIUM"
				tabletVariant="HEADING_2"
				mobileVariant="HEADING_2"
				color="brand-500"
				className={styles.heading}
			>
				Learn more about ELN
			</TypographyV2>

			{QnAs?.map((qna) => {
				return (
					<div key={qna.id}>
						<Accordion
							title={qna.title}
							defaultOpen={qna.defaultOpen}
						>
							{qna.children?.map((body_content, index) => {
								return (
									<div key={index}>
										{getBodyElement(body_content)}
									</div>
								);
							})}
						</Accordion>
					</div>
				);
			})}
		</div>
	);
};
