import React from "react";
import STAR_ORANGE from "@assets/icons/Lims/star-orange.svg";
import { StaticImage } from "gatsby-plugin-image";
import RECTANGLE_2 from "@assets/icons/Lims/rectangle-blue-2.svg";
import RECTANGLE_1 from "@assets/icons/Lims/rectangle-blue-1.svg";
import STAR_BLUE_1 from "@assets/icons/Lims/star-blue-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./CentralProtocolHub.module.scss";
import { WhyLimsProps } from "@components/LimsSoftware/SectionWhyLims/data";
import { SectionWhyLIMS } from "@components/LimsSoftware/SectionWhyLims";
import { useWindowSizeType } from "@helpers/Hooks";

export const CentralProtocolHub = () => {
	const windowSizeType = useWindowSizeType();

	const props: WhyLimsProps = {
		title: "Central protocol hub",
		starOnTitle: <SVG src={STAR_BLUE_1} className={styles.starBlue1} />,
		description:
			"Manage all team protocols in a central hub of electronic lab notebook (ELN), and make protocol management a breeze with easy tracking, accessibility, and organization.",
		list: [
			{
				text: "Easy protocol management and editing",
				icon: STAR_ORANGE,
				id: 1,
			},
			{
				text: "Simplified protocol filtering with labels and tags",
				icon: STAR_ORANGE,
				id: 2,
			},

			{
				text: "Start new projects by importing protocols from the library",
				icon: STAR_ORANGE,
				id: 3,
			},
		],
		link: "PROTOCOLS",
		bodyTextColor: "text-helper",
		image: (
			<div className={styles.imageWrapper}>
				<SVG src={RECTANGLE_2} className={styles.rectangle2} />
				<StaticImage
					loading="eager"
					src={"../../../assets/images/eln/protocol-preview.png"}
					alt="Protocol Preview"
				/>
				<SVG src={RECTANGLE_1} className={styles.rectangle1} />
			</div>
		),
		reverse:
			windowSizeType === "mobile"
				? false
				: true,
	};
	return <SectionWhyLIMS {...props} />;
};
