import React from "react";
import STAR from "@assets/icons/Lims/star.svg";
import { StaticImage } from "gatsby-plugin-image";
import RECTANGLE_3 from "@assets/icons/Lims/rectangle-3.svg";
import RECTANGLE_1 from "@assets/icons/Lims/rectangle-1.svg";
import STAR_GRAY_1 from "@assets/icons/Lims/star-gray-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./Eln.module.scss";
import { WhyLimsProps } from "@components/LimsSoftware/SectionWhyLims/data";
import { SectionWhyLIMS } from "@components/LimsSoftware/SectionWhyLims";

export const ELN = () => {
	const props: WhyLimsProps = {
		title: "Electronic Lab Notebook (ELN)",
		starOnTitle: <SVG src={STAR_GRAY_1} className={styles.starGray1} />,
		description:
			"An Electronic Lab Notebook (ELN) allows scientists to manage experiments and protocols within a single platform and integrate them with sample materials.",
		list: [
			{
				text: "Save up to 17 hours per week by using Genemod ELN",
				icon: STAR,
				id: 1,
			},
			{
				text: "Easily manage all the projects and experiments in one place",
				icon: STAR,
				id: 2,
			},

			{
				text: "Simplify workflow with an intuitive project management system",
				icon: STAR,
				id: 3,
			},
			{
				text: "Integrate inventory directly into ELN and keep experiments accurate",
				icon: STAR,
				id: 4,
			},
		],
		link: "ELN",
		bodyTextColor:'text-helper',
		image: (
			<div className={styles.imageWrapper}>
				<SVG src={RECTANGLE_3} className={styles.rectangle3} />
				<StaticImage
					loading="eager"
					src={"../../../assets/images/eln/ELN-folder-overview.png"}
					alt="ELN Folder Overview"
				/>
				<SVG src={RECTANGLE_1} className={styles.rectangle1} />
			</div>
		),
	};
	return <SectionWhyLIMS {...props} />;
};
